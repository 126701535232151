import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5c0f77c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "has-background-modest level-center is-mobile" }
const _hoisted_2 = ["id", "src", "onerror", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!

  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    (_ctx.path && _ctx.hasThumbnail)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          id: 'the-item-list-thumbnail-'+_ctx.thumbnail,
          src: _ctx.BASE_URL + _ctx.path,
          onerror: _ctx.notThumbnail,
          alt: _ctx.$t('rightPane.thumbnailAlt')
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createBlock(_component_PlaceholderImage, {
          key: 1,
          type: _ctx.placeholderImageType,
          id: 'panel-related-item-thumbnail-' + _ctx.itemIndex
        }, null, 8, ["type", "id"]))
  ]))
}