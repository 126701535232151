import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6229912"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-related-items has-background-contents" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "panel-related-items-list-wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "panel-related-items-list level-left is-mobile",
  ref: "scrollTarget"
}
const _hoisted_5 = { class: "panel-related-items-list-article-body" }
const _hoisted_6 = { class: "panel-related-items-title" }
const _hoisted_7 = { class: "has-text-modest panel-related-items-volume" }
const _hoisted_8 = { class: "has-text-modest panel-related-items-author-publication" }
const _hoisted_9 = { class: "panel-related-items-list-article-tags" }
const _hoisted_10 = { class: "panel-related-items-nav is-hidden-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelRelatedItem = _resolveComponent("PanelRelatedItem")!
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!
  const _component_AppTag = _resolveComponent("AppTag")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('relatedItem.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.relatedItemList.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('relatedItem.message')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedItemList, (itemList, itemListIdx) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemList.list, (item, itemIdx) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.pid,
                    class: "panel-related-items-list-article"
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_TextLinkWithArrow, {
                        to: `/${_ctx.makeRelatedItemUrl(item.pid)}`,
                        href: item.warpUri,
                        target: itemList.id !== '085' ? '' : '_blank',
                        icon: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PanelRelatedItem, {
                            path: item.thumbnail,
                            placeholderImageType: itemList.id !== '085' ? _ctx.hasPlaceholderImageType(item) : 'document',
                            itemIndex: itemListIdx*100 + itemIdx
                          }, null, 8, ["path", "placeholderImageType", "itemIndex"]),
                          _createElementVNode("p", _hoisted_6, _toDisplayString(itemList.id !== '085' ? _ctx.metaArrayProcessing(item.meta, '0001Dtct') : _ctx.$t('relatedItem.warpLabel')), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "href", "target"]),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.createVolumeLabel(item)), 1),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.metaAuthorAndPublication(item.meta, '0010Dtct')), 1),
                      _withDirectives(_createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_AppTag, {
                          text: _ctx.$t(`relatedItem.tag${itemList.id}`)
                        }, null, 8, ["text"])
                      ], 512), [
                        [_vShow, _ctx.$t(`relatedItem.tag${itemList.id}`)]
                      ])
                    ])
                  ]))
                }), 128))
              ], 64))
            }), 256))
          ], 512)),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_ButtonIcon, {
          ref: "prevButton",
          icon: "pagination-prev",
          styled: "is-none",
          class: "panel-related-items-nav-prev has-text-modest shadow level-center",
          "aria-hidden": "true",
          tabindex: "-1"
        }, null, 512),
        _createVNode(_component_ButtonIcon, {
          ref: "nextButton",
          icon: "pagination-next",
          styled: "is-none",
          class: "panel-related-items-nav-next has-text-modest shadow level-center",
          "aria-hidden": "true",
          tabindex: "-1"
        }, null, 512)
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.isShowPanel]
  ])
}