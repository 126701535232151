import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass([_ctx.styled, "app-tag"])
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text), 1),
      (_ctx.removable)
        ? (_openBlock(), _createBlock(_component_DssIcon, {
            key: 0,
            icon: "minus-circle",
            size: "is-small",
            class: "app-tag-remove",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('remove')), ["stop"])),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('remove')), ["prevent","stop"]), ["space","enter"]))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}