
import { defineComponent, ref } from 'vue'
import PlaceholderImage from '../../atoms/PlaceholderImage.vue'
export default defineComponent({
  components: {
    PlaceholderImage,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    placeholderImageType: {
      type: String,
      default: 'file',
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  setup () {
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    const hasThumbnail = ref<boolean>(true)
    const notThumbnail = () => {
      hasThumbnail.value = false
    }

    return {
      BASE_URL,
      hasThumbnail,
      notThumbnail,
    }
  },
})
