
import { defineComponent } from 'vue'
import DssIcon from './DssIcon.vue'
import { StyleName, styleNames } from '../../data/def'

export default defineComponent({
  name: 'AppTag',
  components: {
    DssIcon,
  },
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    text: {
      type: String,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
  },
})
