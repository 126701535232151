/**
 * 関連の資料のアイテムの巻号部分を返却します。
 * @param item itemオブジェクト
 * @returns 0007Dtct 0270Dt 0269Dod 【全号まとめ】
 */

import { getParentCollectionId } from './contentLabel'

export const createVolumeLabel = (item: any): string => {
  if (!item) return ''
  if (!item.meta) return ''

  const meta = item.meta
  const mainCollection = item.collections ? item.collections[0] : 'default'

  return createVolumeMetaLabel(meta, mainCollection, item.parent, item.type)
}

const createVolumeMetaLabel = (meta: any, mainCollection: string, isParent: boolean, contentType: string): string => {
  const volume = volumeProcessing(meta)

  // 全冊/全号まとめ の付与
  let summary = ''
  switch (getParentCollectionId(mainCollection)) {
    case 'A00004':
      // 古典籍資料 > 錦絵
      if (!isParent && contentType === 'branch') summary = '【全冊まとめ】'
      break
    // 【全号まとめ】を表示しないコレクション
    // 歴史的音源/科学映像/脚本/手稿譜/日本占領関係資料
    case 'A00024':
    case 'A00126':
    case 'A00123':
    case 'A00124':
    case 'A00016':
      break
    default:
      if (!isParent && contentType === 'branch') summary = '【全号まとめ】'
  }
  const sep = volume && summary ? ' ' : ''

  return volume + sep + summary
}

/**
 * 巻号部分を出し分ける
 * @param meta メタデータ
 * @returns 巻号部分
 */
const volumeProcessing = (meta: any): string => {
  const volume = meta['0007Dtct'] ? meta['0007Dtct'].join(' ') : '' // 巻次又は部編番号
  const number = meta['0270Dt'] ? meta['0270Dt'].join(' ') : '' // 刊行物号
  const serialNumber = meta['0269Dod'] ? meta['0269Dod'].join(' ') : '' // 刊行物通号
  const firstSep = volume && number ? ' ' : '' // 巻と号の間の分割文字
  const secondSep = (volume || number) && serialNumber ? ' ' : '' // 巻（号）と通号の間の分割文字

  return volume + firstSep + number + secondSep + serialNumber
}
