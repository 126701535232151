export const setSlideUI = (
  scrollTarget: HTMLElement,
  nextButton: HTMLElement,
  prevButton: HTMLElement,
  state: { currentPosition: number },
  gap: number,
  touchSupport = true,
  update = false
) => {
  const getDisplayCount = () => {
    const MOBILE_WIDTH = 768
    const children = scrollTarget.children
    const childWidth = children[0].clientWidth
    // ブラウザ画面の横幅が768px未満の場合、targetWidthはアイテム1つ分の横幅とする
    const targetWidth = window.innerWidth >= MOBILE_WIDTH ? scrollTarget.clientWidth : childWidth
    return Math.max(1, Math.floor((targetWidth + gap * 2) / (childWidth + gap)))
  }

  const calculatePixel = (a: number, b: string) => {
    // a = a ? a.replace('px', '') : '0'
    b = b ? b.replace('px', '') : '0'
    const children = scrollTarget.children
    const childWidth = children[0].clientWidth
    const value = Math.min((Number(a) + Number(b)), 0)
    return Math.max(
      value,
      -Math.max(children.length - getDisplayCount(), 0) * (childWidth + gap)
    ) + 'px'
  }

  if (update) {
    const transition = scrollTarget.style.transition
    state.currentPosition = 1
    scrollTarget.style.transition = 'transform 0s'
    scrollTarget.style.transform = 'translateX(0)'
    setTimeout(() => {
      scrollTarget.style.transition = transition
    }, 1000)
    return
  }

  /**
   * ボタン用イベント
   */
  nextButton.addEventListener('click', (e) => {
    const MOBILE_WIDTH = 768
    const children = scrollTarget.children
    const childWidth = children[0].clientWidth
    const targetWidth = window.innerWidth >= MOBILE_WIDTH ? scrollTarget.clientWidth : childWidth
    state.currentPosition = Math.min(
      state.currentPosition + getDisplayCount(),
      children.length
    )
    const x = scrollTarget.style.transform.match(/-?\d+/)
    const left = (x ? x[0] : 0) as number
    scrollTarget.style.transform = `translateX(${calculatePixel(left, `-${targetWidth + gap}px`)})`
    return state.currentPosition
  })

  prevButton.addEventListener('click', (e) => {
    const MOBILE_WIDTH = 768
    const children = scrollTarget.children
    const childWidth = children[0].clientWidth
    const targetWidth = window.innerWidth >= MOBILE_WIDTH ? scrollTarget.clientWidth : childWidth
    state.currentPosition = Math.max(state.currentPosition - getDisplayCount(), 1)
    const x = scrollTarget.style.transform.match(/-?\d+/)
    const left = (x ? x[0] : 0) as number
    scrollTarget.style.transform = `translateX(${calculatePixel(left, `${targetWidth + gap}px`)})`
    return state.currentPosition
  })

  if (!touchSupport) return
  /**
   * タッチデバイス用イベント
   */
  let touchStart = 0
  let startLeft = 0
  const diff = 16// ピクセル数を指定。指定したピクセル以上の動きがあるとスワイプ操作と判定する

  scrollTarget.addEventListener('touchstart', (e: TouchEvent) => {
    const x = scrollTarget.style.transform.match(/-?\d+/)
    const left = (x ? x[0] : 0) as number
    touchStart = e.changedTouches[0].pageX
    startLeft = e.changedTouches[0].pageX - left
    scrollTarget.style.transition = ''
  },
  { passive: true })

  scrollTarget.addEventListener('touchmove', (e: TouchEvent) => {
    e.preventDefault()
    scrollTarget.style.transform = `translateX(${(e.changedTouches[0].pageX - startLeft)}px)`
  },
  { passive: true })

  scrollTarget.addEventListener('touchend', (e: TouchEvent) => {
    const children = scrollTarget.children
    const childWidth = children[0].clientWidth
    const touchend = e.changedTouches[0].pageX

    if (touchStart - touchend > diff) {
      state.currentPosition = Math.min(state.currentPosition + 1, children.length)
    } else if (touchStart - touchend < -diff) {
      state.currentPosition = Math.max(state.currentPosition - 1, 1)
    }

    const index = state.currentPosition - 1
    scrollTarget.style.transition = 'transform .3s ease-out'
    const x = (-childWidth * index) - ((index) * gap)
    scrollTarget.style.transform = `translateX(${x}px)`
    return state.currentPosition
  },
  { passive: true })
}
